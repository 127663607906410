import React from 'react'
import { Container, Text } from 'bumbag'
import { TBooking, TOrder } from '../../../types'
import { SAUSAGE_SIZZLE_EVENT } from '../../../constants'
import { useHistory, useParams } from 'react-router-dom'

const EventContainer = ({
  children,
  onClick,
  selected
}: {
  children: React.ReactNode
  onClick: () => void
  selected: boolean
}) => {
  return (
    <Container {...(!selected && { paddingX: 'major-2' })} cursor={'pointer'}>
      <Container
        display="flex"
        flexDirection="column"
        backgroundColor="fff"
        borderRadius="0.5rem"
        padding={selected ? 'major-3' : 'major-2'}
        border={selected ? '0.5px solid #0D5257' : '1px solid #eee'}
        {...(selected && {
          boxShadow:
            'rgb(0 0 0 / 15%) 0px 2px 8px 0px, rgb(0 0 0 / 2%) 0px 0px 4px 2px',
          marginY: 'major-1'
        })}
        onClick={onClick}
      >
        {children}
      </Container>
    </Container>
  )
}

export const EventSelector = ({
  bookings,
  setBooking,
  setOrder
}: {
  bookings: TBooking[] | undefined
  setBooking: (booking: TBooking) => void
  setOrder: (order: TOrder) => void
}) => {
  const history = useHistory()
  const { storeCode } = useParams<{ storeCode: string }>()

  const { bookingId } = useParams<{ storeCode: string; bookingId: string }>()

  const selectedBooking = bookings?.find(
    (booking: TBooking) => booking.bookingId === bookingId
  )

  return (
    <Container
      display="flex"
      flexDirection="column"
      color="#0D5257"
      alignItems="center"
      padding="major-2"
    >
      <Text
        fontWeight="900"
        fontSize="20px"
        textTransform="capitalize"
        textAlign="left"
        marginY="major-2"
      >
        Today's Sizzle
      </Text>
      {bookings
        ?.filter(
          (booking: TBooking) => booking.eventType === SAUSAGE_SIZZLE_EVENT
        )
        .map((booking: TBooking) => {
          return (
            <EventContainer
              key={`event-${booking.bookingId}`}
              onClick={() => {
                setBooking(booking)
                history.push(`/${storeCode}/${booking.bookingId}`)
              }}
              selected={booking.bookingId === selectedBooking?.bookingId}
            >
              <Text fontWeight="500" fontSize="18px">
                {booking.name === SAUSAGE_SIZZLE_EVENT
                  ? 'Sausage Sizzle'
                  : booking.name}
              </Text>
              <Text fontSize="14px">{booking.vendor.name}</Text>
            </EventContainer>
          )
        })}
      <Text
        fontWeight="700"
        fontSize="18px"
        textTransform="capitalize"
        textAlign="left"
        marginTop="major-4"
        marginBottom="major-1"
      >
        Other store events
      </Text>
      <Container display="flex" flexDirection="column" rowGap="0.5rem">
        {bookings
          ?.filter(
            (booking: TBooking) => booking.eventType !== SAUSAGE_SIZZLE_EVENT
          )
          .map((booking: TBooking) => {
            return (
              <EventContainer
                key={`event-${booking.bookingId}`}
                onClick={() => {
                  setBooking(booking)
                  history.push(`/${storeCode}/${booking.bookingId}`)
                  setOrder([])
                }}
                selected={booking.bookingId === selectedBooking?.bookingId}
              >
                <Text
                  fontWeight="500"
                  fontSize="18px"
                  textTransform="capitalize"
                >
                  {booking.name ?? '---'}
                </Text>
                <Text fontSize="14px">{booking.vendor.name}</Text>
              </EventContainer>
            )
          })}
      </Container>
    </Container>
  )
}
